import React from "react";
import styled from "styled-components";

import { withPrefix } from "gatsby";

import { Globalstyle } from "../style/reset";
import { DATA } from "../data";
//import { OpenstreetMap } from "../components/OpenstreetMap";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

const ImpressumPageWrapper = styled.div`
  background-color: black;
  width: 100%;
`;

const Logo = styled.img`
  width: 96px;
  margin-bottom: 24px;
`;

const IntroWrapper = styled.div`
  padding: auto 16px 16px 16px;
  min-height: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SectionTitle = styled.h2`
  color: ${props => (props.light ? "white" : "black")};
  font-size: 22px;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto 64px auto;
  max-width: 560px;
  text-align: left;
`;

const ImpressumTitle = styled.h1`
  color: white;
  text-align: center;
  width: 100%;
  margin-top: 48px;
`;

const Section = styled.div`
  padding: 48px 16px;
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 560px;
  margin: auto;

  p {
    color: white;
    line-height: 22px;
    font-size: 14px;
  }

  a {
    color: ${DATA.color};
  }
`;

const Addresses = styled.div`
  display: flex;
  flex-direction: row;
`;

const Address = styled.div`
  &:first-child {
    margin-right: 36px;
  }

  &:last-child {
    margin-left: 36px;
  }
`;

const AddressTitle = styled.h3`
  color: white;
  margin-bottom: 16px;
`;

const Content = styled.div`
  padding-bottom: 144px;
  padding: 16px;
  z-index: 2;
`;

class ImpressumPage extends React.Component {
  render() {
    const linkPhone=`tel:${DATA.contact.number}`
    const linkEmail=`mailto:${DATA.contact.email}`
    const linkWebsite=`https://${DATA.contact.website}`
    return (
      <ImpressumPageWrapper>
        <Globalstyle />
        <Header />
        <Content>
          <IntroWrapper>
            <Logo src={withPrefix(`/${DATA.logoBw}`)} />
            <ImpressumTitle>Impressum - Imprint</ImpressumTitle>
          </IntroWrapper>
          <Section>
            <SectionTitle light>UNTERNEHMEN</SectionTitle>
            <SectionContent>
              <Addresses>
                <Address>
                  <AddressTitle>Domicile</AddressTitle>
                  {DATA.addresses.domicile.map((t, k) => (
                    <p key={k}>{t}</p>
                  ))}
                </Address>
                <Address>
                  <AddressTitle>Office</AddressTitle>
                  {DATA.addresses.office.map((t, k) => (
                    <p key={k}>{t}</p>
                  ))}
                </Address>
              </Addresses>
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle light>{DATA.lead.title}</SectionTitle>
            <SectionContent>
              <p>a) {DATA.lead.names[0]}</p>
              <p>b) {DATA.lead.names[1]} </p>
              <br />
              {DATA.lead.text.map((t, k) => (
                <p key={k}>{t}</p>
              ))}
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle light>Kontakt</SectionTitle>
            <SectionContent>
              <p>
                Telefon: <a href={linkPhone}>{DATA.contact.number}</a>
              </p>
              <p>
                E-Mail: <a href={linkEmail}>{DATA.contact.email}</a>
              </p>
              <p>
                Internetadresse: <a href={linkWebsite}>{DATA.contact.website}</a>
              </p>
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle light>{DATA.responsible.title}</SectionTitle>
            <SectionContent>
              <p>{DATA.responsible.name} </p>
            </SectionContent>
          </Section>
          {DATA.legals.map((l, k) => (
            <Section key={k}>
              <SectionTitle light>{l.title}</SectionTitle>
              <SectionContent>
                <p>{l.text}</p>
              </SectionContent>
            </Section>
          ))}
        </Content>
        <Footer />
      </ImpressumPageWrapper>
    );
  }
}

export default ImpressumPage;
